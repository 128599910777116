audio::-webkit-media-controls-panel {
  @apply bg-[#F0F7FF];
}

.flashcard-container {
  @apply flex items-center justify-center;
}

.flashcard-radio-tile-group {
  @apply flex flex-wrap justify-center;
}

.flashcard-input-container {
  @apply m-[2rem] w-[18.76056rem] h-[15.31475rem] flex relative items-center justify-center;
}

.flashcard-input-container input {
  @apply absolute w-[18.76056rem] h-full m-0 cursor-pointer z-[2] opacity-0;
}

.flashcard-input-container .flashcard-radio-tile {
  @apply relative flex items-center justify-center w-[18.76056rem] h-full;
}

.flashcard-radio-tile img {
  @apply absolute -top-4 -right-4 rounded-full bg-white hidden;
}

.flashcard-input-container label {
  @apply relative flex flex-col items-center justify-center w-full h-full;
}
.flashcard-radio-tile .label div {
  @apply w-[18.76056rem] h-[3.55519rem] bg-[#2947A9] flex items-center px-4;
}

.flashcard-input-container label p {
  @apply relative text-base text-center font-bold leading-normal;
  color: var(--neutral-white, #fff);
}

.flashcard-radio-tile .label img {
  @apply w-[18.76056rem] h-[11.75956rem] block relative rounded-none top-0 left-0 object-fill;
}

input:checked + .flashcard-radio-tile img {
  @apply block;
}
