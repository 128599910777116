.video-container {
  @apply flex items-center justify-center;
}

.video-radio-tile-group {
  @apply flex flex-wrap justify-center;
}

.video-input-container {
  @apply m-[1.35rem] min-w-[6.4375rem] max-w-[9rem] h-[5.3125rem] flex relative items-center justify-center;
}

.video-input-container input {
  @apply absolute w-full h-full m-0 cursor-pointer z-[2] opacity-0;
}

.video-input-container .video-radio-tile {
  @apply relative flex items-center justify-center w-full h-full rounded-[0.625rem] border border-solid border-[#A3AED0] px-1;
}

.video-radio-tile img {
  @apply absolute -top-2 -right-2 rounded-full bg-white hidden;
}

.video-input-container label {
  @apply text-[#6274AC] text-base text-center font-normal leading-[133.333%];
}

/* input:checked + .video-radio-tile img {
  @apply block;
} */
