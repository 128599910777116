.MuiFormControl-root {
  @apply w-[100%] rounded-[0.625rem];
}

.MuiInputBase-root.MuiOutlinedInput-root {
  @apply pl-2 text-black text-[0.875rem] font-normal leading-3 rounded-[0.625rem] bg-white border border-solid border-[#859BCA] hover:outline-none focus:outline-none;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  @apply fill-[#859BCA];
}

fieldset.MuiOutlinedInput-notchedOutline {
  @apply rounded-[0.625rem] hover:border-none focus:outline-none border-none;
}
