audio::-webkit-media-controls-panel {
  @apply bg-[#F0F7FF];
  color: #28b446;
}

audio::-webkit-media-controls-timeline {
  color: #28b446;
}

.conversation-container {
  @apply flex items-center justify-center;
}

.conversation-radio-tile-group {
  @apply flex flex-wrap justify-center;
}

.conversation-input-container {
  @apply m-[1.35rem] min-w-[6.4375rem] max-w-[9rem] h-[5.3125rem] flex relative items-center justify-center;
}

.conversation-input-container input {
  @apply absolute w-full h-full m-0 cursor-pointer z-[2] opacity-0;
}

.conversation-input-container .conversation-radio-tile {
  @apply relative flex items-center justify-center w-full h-full rounded-[0.625rem] border border-solid border-[#A3AED0] px-1;
}

.conversation-radio-tile img {
  @apply absolute -top-2 -right-2 rounded-full bg-white hidden;
}

.conversation-input-container label {
  @apply text-[#6274AC] text-base text-center font-normal leading-[133.333%];
}

input:checked + .conversation-radio-tile img {
  @apply block;
}
