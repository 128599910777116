.button-copy {
  align-items: center;
  background-color: #548bf9;
  border: 2px solid #f9f3f3;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 3.5rem;
  justify-content: center;
  line-height: 1.8rem;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-copy:hover:after {
  transform: translate(0, 0);
}

.button-copy:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-copy {
    padding: 0 30px;
  }
}
