.container {
  @apply flex items-center px-[10%] md:px-0 justify-start md:justify-center;
}

.radio-tile-group {
  @apply flex flex-col items-start justify-center md:min-w-[32rem];
}

.input-container {
  @apply m-[0.8rem] md:min-w-[7rem] md:max-w-[19rem] min-h-[1.65rem] flex relative items-center justify-center;
}

.input-container input {
  @apply absolute w-full h-full m-0 cursor-pointer z-[2] opacity-0;
}

.input-container .radio-tile {
  @apply relative flex items-center justify-start w-full h-full;
}

.input-container label {
  @apply font-['DM_Sans'] text-[#7D8BB5] text-sm text-center font-normal leading-[175%] flex items-center justify-center ml-[0.8rem];
}

.input-container img {
  @apply w-[1.0625rem] h-[1.0625rem] rounded-full;
}

.radio-tile #check {
  @apply hidden;
}

input:checked + .radio-tile #check {
  @apply block;
}

input:checked + .radio-tile #check-box {
  @apply hidden;
}

input:checked + .radio-tile label {
  @apply text-[#3D10EA];
}
