span[data-text] {
  display: inline-block;
  font-weight: bold;
  position: relative;
}
span[data-text]:before {
  content: attr(data-text);
  background: linear-gradient(180deg, #ffdb9d 20%, #fc9d45 80%);
  -webkit-text-stroke: 2px transparent;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
span[data-text]:after {
  content: attr(data-text);
  left: 0;
  top: 0;
  position: absolute;
  background-image: linear-gradient(102deg, #fbb666 0%, #ffdb9d 131.31%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
